<template>
  <div class="container">
    <p class="has-text-weight-semibold box mt-2">
      {{ $t('omw-kill-switch-intro') }}
    </p>
    <b-table
      :data="killSwitchData"
      :loading="isLoading"
      detailed
      detail-key="id"
      :show-detail-icon="true"
      style="width: 60vw; margin: auto"
      class="mt-2"
    >
      <template slot="empty">
        <p class="has-text-centered">{{ $t('No data to display') }}</p>
      </template>
      <b-table-column :visible="false" field="id" label="id" v-slot="props">
        <template>
          {{ `${props.row.id}` }}
        </template>
      </b-table-column>
      <b-table-column field="startTime" label="Start Time" v-slot="props">
        <template>
          {{ `${formatDate(props.row.startTime)}` }}
        </template>
      </b-table-column>
      <b-table-column field="endTime" label="End Time" v-slot="props">
        <template>
          {{ `${formatDate(props.row.endTime)}` }}
        </template>
      </b-table-column>
      <b-table-column field="message" label="HTML" sortable v-slot="props">
        <template>
          <b-icon
            :icon="getIcon(isHtml(props.row.message))"
            :type="getIconColor(isHtml(props.row.message))"
          ></b-icon>
        </template>
      </b-table-column>
      <b-table-column v-slot="props">
        <template>
          <b-button
            size="is-small"
            type="is-primary"
            @click="showUpdateDialog(props.row.id)"
            >Update</b-button
          >
        </template>
      </b-table-column>
      <b-table-column v-slot="props">
        <template>
          <b-button
            size="is-small"
            type="is-danger"
            @click="deleteOutage(props.row.id)"
            :loading="deleteButtonLoadingStates[props.row.id]"
            >Delete</b-button
          >
        </template>
      </b-table-column>
      <template #detail="props">
        <p v-html="props.row.message"></p>
      </template>
    </b-table>
    <div class="center">
      <p class="is-danger" v-if="error">{{ error }}</p>
      <div class="buttons mt-6">
        <b-button type="is-primary" @click="showCreateDialog"
          >Create Outage</b-button
        >
        <b-button type="is-primary" @click="fetchData">Fetch Outages</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import {
  getAllKillSwitchConfigs,
  deleteKillSwitchConfig,
} from '@/services/omwKillSwitch';

import { defineComponent } from '@vue/composition-api';
import KillSwitchCreate from './KillSwitchCreate.vue';
import KillSwitchUpdate from './KillSwitchUpdate.vue';

export default defineComponent({
  name: 'KillSwitch',
  data() {
    return {
      killSwitchData: [],
      dateColWidth: '0',
      error: undefined,
      deleteButtonLoadingStates: {},
      isLoading: false,
    };
  },
  async created() {
    this.fetchData();
  },
  methods: {
    formatDate(inputDate) {
      const dateTime = DateTime.fromISO(inputDate);
      return dateTime.toFormat('dd/MM/yyyy HH:mm');
    },
    showCreateDialog() {
      this.$buefy.modal.open({
        parent: this,
        component: KillSwitchCreate,
        hasModalCard: false,
        width: 960,
        events: {
          'created-config': () => {
            this.fetchData();
          },
        },
      });
    },
    showUpdateDialog(id) {
      const outage = this.killSwitchData.filter(
        (outageItem) => outageItem.id === id,
      );
      this.$buefy.modal.open({
        parent: this,
        component: KillSwitchUpdate,
        hasModalCard: false,
        width: 960,
        events: {
          'updated-config': () => {
            this.fetchData();
          },
        },
        props: {
          outage: outage[0],
        },
      });
    },
    async fetchData() {
      this.error = undefined;
      this.isLoading = true;
      try {
        this.killSwitchData = await getAllKillSwitchConfigs();
        this.deleteButtonLoadingStates = this.killSwitchData.reduce(
          (prev, curr) => {
            prev[curr.id] = false;
            return prev;
          },
          {},
        );
      } catch (err) {
        this.error = err.message;
      } finally {
        this.isLoading = false;
      }
    },
    async deleteOutage(id) {
      this.error = undefined;
      try {
        this.deleteButtonLoadingStates[id] = true;
        await deleteKillSwitchConfig(id);
        delete this.deleteButtonLoadingStates[id];
        this.fetchData();
      } catch (err) {
        this.error = err.message;
      }
    },
    getIcon(value) {
      if (value) {
        return 'check';
      } else {
        return 'times';
      }
    },
    getIconColor(value) {
      if (value) {
        return 'is-success';
      } else {
        return 'is-danger';
      }
    },
    isHtml(data) {
      return data?.includes('/>') || data.includes('</');
    },
  },
});
</script>
<style>
.center {
  display: grid;
  place-items: center;
}
</style>
