<template>
  <div>
    <div style="width: 90%" class="has-text-centered">
      <span v-html="previewText"></span>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
export default defineComponent({
  name: 'KillSwitchPreview',
  props: {
    previewText: {
      type: String,
      required: true,
      default: '',
    },
  },
});
</script>
