import { ToastProgrammatic as Toast } from 'buefy';

/**
 *
 * @param {start | end} startOrEnd
 * @returns {Date}
 */
export function getNearestDateTime(startOrEnd) {
  const coeff = 1000 * 60 * 10;
  const date = new Date();
  if (startOrEnd === 'start') {
    return new Date(Math.floor(date.getTime() / coeff) * coeff);
  }
  if (startOrEnd === 'end') {
    return new Date(Math.ceil(date.getTime() / coeff) * coeff);
  }
}

/**
 *
 * @returns {Date}
 */
export function getMinDateTime() {
  const min = new Date();
  return new Date(min.setDate(min.getDate() - 1));
}

const toastConfig = {
  duration: 5000,
  type: 'is-info',
};

export function addTelTag() {
  const telTag = `<a href="tel:0123123123">call</a>`;
  if (this.message) {
    this.message += telTag;
  } else {
    this.message = telTag;
  }
  toastConfig.message = `Don't forget to update the phone number in the message!`;
  Toast.open(toastConfig);
}

export function addEmailTag() {
  const emailTag = `<a href="mailto:someone@help.com">email</a>`;
  if (this.message) {
    this.message += emailTag;
  } else {
    this.message = emailTag;
  }
  toastConfig.message = `Don't forget to update the email address in the message!`;
  Toast.open(toastConfig);
}
