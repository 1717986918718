<template>
  <ValidationObserver v-slot="{ invalid }" ref="valobs">
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">Update Outage Config</p>
      </header>
      <section class="modal-card-body" style="height: 80vh">
        <div class="columns">
          <div class="column">
            <b-field label="Outage start time">
              <b-datetimepicker
                v-model="startTime"
                placeholder="Click to select start time"
                icon="calendar"
                :icon-right="startTime ? 'close-circle' : ''"
                icon-right-clickable
                @icon-right-click="clearDateTime('start')"
                :first-day-of-week="firstDayOfWeek"
                :locale="locale"
                :datepicker="{ showWeekNumber }"
                :timepicker="{ enableSeconds, hourFormat, incrementMinutes }"
                :minDatetime="minDatetime"
              >
                <template #left>
                  <b-button
                    label="Now"
                    type="is-primary"
                    icon-left="clock"
                    @click="startTime = getNearestDateTime('start')"
                  />
                </template>
              </b-datetimepicker>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Outage end time">
              <b-datetimepicker
                v-model="endTime"
                placeholder="Click to select end time..."
                icon="calendar"
                :icon-right="endTime ? 'close-circle' : ''"
                icon-right-clickable
                @icon-right-click="clearDateTime('end')"
                :first-day-of-week="firstDayOfWeek"
                :locale="locale"
                :datepicker="{ showWeekNumber }"
                :timepicker="{ enableSeconds, hourFormat, incrementMinutes }"
              >
                <template #left>
                  <b-button
                    label="Now"
                    type="is-primary"
                    icon-left="clock"
                    @click="endTime = getNearestDateTime('end')"
                  />
                </template>
              </b-datetimepicker>
            </b-field>
          </div>
        </div>
        <div>
          <b-field label="Message">
            <ValidationProvider
              v-slot="{ errors }"
              name="Outage message"
              rules="required"
            >
              <b-input type="textarea" v-model="message">Message</b-input>
              <p class="has-text-danger" v-if="errors.length">
                {{ errors[0] }}
              </p>
            </ValidationProvider>
          </b-field>
          <kill-switch-preview
            class="mb-4"
            style="min-height: 6rem"
            :preview-text="message"
          ></kill-switch-preview>
          <div class="buttons">
            <b-button
              :loading="isLoading"
              :disabled="invalid"
              type="is-primary"
              @click="updateOutageConfig"
              >Update outage</b-button
            >
            <b-button type="is-info" @click="addTelTag">Add Tel Tag</b-button>
            <b-button type="is-info" @click="addEmailTag"
              >Add Email Tag</b-button
            >
          </div>
        </div>
      </section>
    </div>
  </ValidationObserver>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
  setInteractionMode,
} from 'vee-validate';
import { defineComponent } from '@vue/composition-api';

setInteractionMode('aggressive');

import { updateKillSwitchConfig } from '@/services/omwKillSwitch';
import KillSwitchPreview from '@/components/omw/killSwitch/KillSwitchPreview.vue';

import {
  getNearestDateTime,
  getMinDateTime,
  addEmailTag,
  addTelTag,
} from '@/components/omw/killSwitch/killSwitchUtils';

export default defineComponent({
  name: 'KillSwitchUpdate',
  components: {
    ValidationProvider,
    ValidationObserver,
    KillSwitchPreview,
  },
  props: {
    outage: {
      type: Object,
      required: true,
      default: undefined,
    },
  },
  data() {
    return {
      id: this.outage.id,
      startTime: new Date(this.outage.startTime),
      endTime: new Date(this.outage.endTime),
      message: this.outage.message,
      showWeekNumber: false,
      enableSeconds: false,
      hourFormat: undefined, // Browser locale
      locale: undefined, // Browser locale
      firstDayOfWeek: 1, // 1 - Monday
      incrementMinutes: 10,
      isLoading: false,
      minDatetime: new Date(getMinDateTime()),
    };
  },
  methods: {
    getNearestDateTime,
    addEmailTag,
    addTelTag,
    clearDateTime(startOrEnd) {
      if (startOrEnd === 'start') {
        this.startTime = null;
      }
      if (startOrEnd === 'end') {
        this.endTime = null;
      }
    },
    async updateOutageConfig() {
      try {
        this.isLoading = true;
        const startTimeStr = this.startTime.toISOString();
        const endTimeStr = this.endTime.toISOString();
        await updateKillSwitchConfig({
          id: this.id,
          startTime: startTimeStr,
          endTime: endTimeStr,
          message: this.message,
        });
        this.$emit('updated-config');
        this.$parent.close();
      } finally {
        this.isLoading = false;
      }
    },
  },
});
</script>
