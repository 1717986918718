import axios from 'axios';
import configData from '../config/config.json';
import { getIdToken } from '@/services/auth/index';

const path = 'kill-switch';

const apiClient = axios.create({
  baseURL: configData.killSwitch.baseUrl,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

apiClient.interceptors.request.use(
  (config) => {
    const token = getIdToken();
    if (token) {
      config.headers.authorization = `Bearer ${token}`;
      return config;
    }
    throw new Error('No access token Found');
  },
  (err) => Promise.reject(err),
);

/**
 * @param {Object} config
 * @param {string} config.startTime
 * @param {string} config.endTime
 * @param {string} config.message
 *
 */
export async function createKillSwitchConfig(config) {
  await apiClient.post(`/${path}`, config);
}

export async function getAllKillSwitchConfigs() {
  const { data } = await apiClient.get(`/${path}`);
  return data;
}

export async function deleteKillSwitchConfig(id) {
  await apiClient.delete(`/${path}/${id}`);
}

export async function updateKillSwitchConfig(outage) {
  await apiClient.patch(`/${path}/${outage.id}`, {
    startTime: outage.startTime,
    endTime: outage.endTime,
    message: outage.message,
  });
}
